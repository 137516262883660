<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <h2 class="mb-1 text-primary">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.title') }}
        </h2>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />

            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-payment-term-create' }">
              <span class="text-nowrap">{{
                $t(RESOURCES_ELEMENT_NAME + '.actions.create')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <!-- column: name -->
        <template #cell(arl)="data">
          {{ data.item.arl.name }}
        </template>

        <template #cell(term)="data">
          {{ data.item.term.value }}
        </template>

        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="`edit-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              :to="{
                name: 'apps-payment-term-edit',
                params: { id: data.item.id },
              }">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- ? EDIT -->
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import ElementStoreModule from '../storeModule'

const SIZE_LIST = [50, 100]

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {},
      arlList: [],
      paymentTermList: [],
      sizeList: SIZE_LIST,
    }
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'paymentTerm'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  async mounted() {
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.tableColumns = [
      {
        key: 'arl',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl_name`),
        sortable: true,
      },
      {
        key: 'term',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.term`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    data && (this.query = data.query)

    if (this.searchData) {
      this.elementData = this.searchData
    } else {
      this.elementData = {
        arl_id: null,
        from: moment().subtract(30, 'days').toDate(),
        until: moment().toDate(),
        size: this.sizeList[0],
        rips: 0,
      }
    }
    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    async send() {},
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({ query: this.query })
      )

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
